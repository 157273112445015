// Bootstrap variable Override

$breadcrumb-divider: quote(">");

//translations
$custom-file-text: (
  en: "Browse",
  fr: "Parcourir"
);

$primary: #ffc107;

@import "~bootstrap/scss/bootstrap";

/*
.fluid-img {
  width: 100%;
  height: 25vh;
  object-fit: cover;
}
*/

.bg-jurassic-park {
  background-image: url("assets/jp.png"); /* The image used */
  background-position: center; /* Center the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  height: 100%;
}

@keyframes rotating {
  from{
    transform: rotate(0deg);
    color: $danger;

  }
  50% {
    color: orange;
  }
  to{
    transform: rotate(360deg);
    color: $danger;
  }
}

.rotating {
  animation: rotating 2s linear infinite;
}


@keyframes important {
  from{
    color: $primary;
  }
  50% {
    color: $success;
  }
  to{
    color: $primary;
  }
}

.important {
  animation: important 1s linear 10;
}